<template>
  <div class="content">
    <h2>404 - Seite nicht gefunden!</h2>
    <p>
      Tut uns leid, die Seite: "www.kitchen101.de{{ getUrl }}" gibt es nicht!
    </p>
  </div>
</template>

<script>
export default {
  name: "Recipes",
  computed: {
    getUrl: function () {
      return this.$route.path;
    },
  },
};
</script>

<style scoped>
.content {
  margin: 4.5em 0.5em 4.5em 0.5em;
}
</style>
