<template>
  <div class="spacer">
    <h1>Das Team</h1>
  </div>
  <div class="row middle">
    <div class="col-lg-1"></div>
    <div id="main-content" class="col-lg-10">
      <div
        v-for="index in 5"
        :key="index"
        class="d-flex justify-content-center"
      >
        <person-component
          :name="images[index - 1].name"
          :width="images[index - 1].width"
          :height="images[index - 1].height"
          :description="descriptions[index - 1].description"
          :imgUrl="images[index - 1].imgUrl"
        ></person-component>
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
</template>

<script>
import PersonComponent from "../components/PersonComponent.vue";
export default {
  name: "Team",
  data: function () {
    return {
      descriptions: [
        {
          description: `Ich bin Aleyna Kantar, 22 Jahre alt und arbeite als
            Veranstaltungskauffrau bei der mehrpunkt GmbH & Co KG in Freiburg.
            Hier bin ich hauptsächlich für die Betreuung der LOKation und den
            Social Media Bereich zuständig. Dazu gehört primär der Erstkontakt
            mit Kunden bei neuen Anfragen, die Absprache mit Köchen, das Briefen
            des Personals und die Betreuung der Kunden im Planungsprozess.
            Auch im Social Media Bereich unterstütze ich meine Kollegin mit der
            Erarbeitung neuer Inhalte, Planung und Durchführung von Videodrehs
            und Fotoshootings, sowie die Bearbeitung und Fertigstellung des
            entstandenen Video- und Bildmaterials. Meine Leidenschaft für
            Ordnung und Planung geben unseren Kunden und mir die Sicherheit,
            dass nichts in Vergessenheit gerät. Diese Fähigkeiten stehen für die
            Planung und Realisierung Ihrer Events immer im Vordergrund. Meine
            Erfahrungen mit Teamkoch-Events, thematisierten Küchenpartys sowie
            jeglichen Privat Veranstaltungen können Ihnen die Sicherheit geben,
            dass auch Ihr virtuelles Kochen ein erfolgreicher Abend sein wird!`,
        },
        {
          description: `Mein Name ist Anna-Lena, ich bin 21 Jahre alt und komme aus der Nähe von Freiburg.
            Aktuell bin ich im zweiten Ausbildungsjahr meiner Ausbildung zur Veranstaltungskauffrau.
            Die Ausbildung mache ich wie Judith, bei der Haufe Akademie in Freiburg.
            Die Haufe Akademie ist ein Weiterbildungsunternehmen. Wir bieten Fort- und Weiterbildungen in Form von
            Seminaren, Tagungen, Lehrgängen, eLearnings und Blende-Learnings für Firmen und privat Personen an.
            Ich freue mich meine Erfahrungen zu Online-Veranstaltungen in unser Projekt mit einbringen zu können.`,
        },
        {
          description: `Mein Name ist Judith, ich bin 21 Jahre alt und komme aus Waldkirch.
            Ich bin derzeit im zweiten Lehrjahr meiner Ausbildung zur
            Veranstaltungskauffrau. Die Ausbildung mache ich bei der Haufe
            Akademie, einem Weiterbildungsunternehmen in Freiburg. Wir bieten
            Fort- und Weiterbildungen in Form von Seminaren, Tagungen,
            Lehrgängen, eLearnings und Blended-Learnings an. Da im Moment keine
            Präsenz-Seminare stattfinden können, hat sich die Haufe Akademie
            inzwischen auch auf Live-Online-Trainings spezialisiert. Somit freue
            ich mich sehr, nun auch ein Teil eines Teams zu sein, welches eine
            Veranstaltung auf Distanz plant und durchführt.`,
        },
        {
          description: `Mein Name ist Kira Kübler, ich bin 20 Jahre alt und absolviere meine Ausbildung
            im Restaurant Sichtwerk in Eimeldingen. Die G5 Service GmbH ist ein Gastronomie und Event Betrieb im
            Dreiländereck Deutschland-Schweiz-Frankreich. Sie ist Betreiber des Restaurants Sichtwerk, ein
            à la carte Restaurant mit über 180 Sitzplätzen. Zur Durchführung von Veranstaltungen verfügt das
            G5 verschiedene Veranstaltungsräume von 25m² bis zu 800m². Das Gebäude besitzt dazu noch eine Gartenfläche von 5000m².
            Wir veranstalten jährlich über 200 Events unterschiedlicher Art. Dazu zählen private Feiern wie Taufen, Geburtstage
            und Hochzeiten sowie auch Business Events wie MICE Veranstaltungen und Weihnachtsfeiern.`,
        },
        {
          description: `Mein Name ist Selina Kuolt, ich bin 20 Jahre alt und in Konstanz am
            Bodensee geboren und aufgewachsen, wo ich auch meine
            Ausbildung als Veranstaltungskauffrau mache. Mein Betrieb ist das
            Theater Konstanz, dort drehen sich natürlich alle Veranstaltungen
            ums Theater. Mit drei Spielstätten, dem Stadttheater, der
            Spiegelhalle und der Werkstatt, haben wir vieles im Angebot. Von
            eigenen Inszenierungen über Jugendstücke bis hin zu Lesungen planen wir
            alles selbst. Unsere Werkstätten können dabei vieles selber
            herstellen, d.h. Requisiten, Bühnenbildnisse und Co sind auch von
            uns. Als Auszubildende durchlaufe ich verschiedene Abteilungen wie
            z.B. das Marketing und das Backoffice. So bekomme ich einen
            Überblick über die Planung, Durchführung und Nachbereitung
            von Veranstaltungen.`,
        },
      ],
    };
  },
  computed: {
    images() {
      return this.$store.getters.images;
    },
  },
  components: {
    PersonComponent,
  },
};
</script>
