import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Team from '../views/Team.vue'
import Video from '../views/Video.vue'
import Recipes from '../views/Recipes.vue'
import Sponsoring from '../views/Sponsoring.vue'
import Registration from '../views/Registration.vue'
import Impressum from '../views/Impressum.vue'
import PageNotFound from '../views/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/rezepte',
    name: 'Rezepte',
    component: Recipes
  },
  {
    path: '/anmeldung',
    name: 'Anemldung',
    component: Registration
  },
  {
    path: '/sponsoring-spenden',
    name: 'Sponsoring',
    component: Sponsoring
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
