
<template>
  <HeaderComponent></HeaderComponent>
  <main style="margin-top: 210px">
    <LoeffelComponent></LoeffelComponent>
    <router-view></router-view>
    <LoeffelComponent></LoeffelComponent>
  </main>
  <FooterComponent></FooterComponent>
</template>

<script>
import HeaderComponent from "../src/components/HeaderComponent.vue";
import FooterComponent from "../src/components/FooterComponent.vue";
import LoeffelComponent from "../src/components/loeffelComponent.vue";

export default {
  data() {
    return {
      SCROLL_OFFSET: 60,
    };
  },
  mounted: function () {
    let self = this;
    window.onscroll = function () {
      self.scrollFunction();
    };
  },
  methods: {
    scrollFunction: function () {
      let scroll = document.documentElement.scrollTop;

      if (210 - scroll < 60) {
        document.getElementById("header-logo").style.width = "60px";
        document.getElementById("header-logo").style.height = "60px";
      } else {
        document.getElementById("header-logo").style.width =
          210 - scroll + "px";
        document.getElementById("header-logo").style.height =
          210 - scroll + "px";
      }
    },
  },
  components: {
    HeaderComponent,
    FooterComponent,
    LoeffelComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: hidden;
}

h1 {
  font-family: "Courgette", serif;
  font-size: 50px;
}

h2 {
  font-family: "Courgette", serif;
}

p {
  font-family: "Montserrat", serif;
}

.middle {
  background-image: url("~@/assets/background.jpg");
  background-color: #ffffff;
  margin-left: 2vw;
  margin-right: 2vw;
}

.content-box {
  background-color: #73bfcf;
  padding: 1.7em;
  margin-top: 2em;
  margin-bottom: 2em;
  outline: 2px solid #ffffff;
  outline-offset: -10px;
  max-width: 80em;
  margin-left: 0.4em;
  margin-right: 0.4em;
  -webkit-box-shadow: 8px 9px 8px -2px rgba(0, 0, 0, 0.74);
  box-shadow: 8px 9px 8px -2px rgba(0, 0, 0, 0.74);
}

.spacer {
  margin-top: 2em;
}

body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.content-wrapper {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.button {
  display: inline-block;
  margin: 1em;

  -webkit-border-radius: 10px;

  -webkit-box-shadow: 0px 3px rgba(128, 128, 128, 1),
    /* gradient effects */ 0px 4px rgba(118, 118, 118, 1),
    0px 5px rgba(108, 108, 108, 1), 0px 6px rgba(98, 98, 98, 1),
    0px 7px rgba(88, 88, 88, 1), 0px 8px rgba(78, 78, 78, 1),
    0px 14px 6px -1px rgba(128, 128, 128, 1); /* shadow */

  -webkit-transition: -webkit-box-shadow 0.1s ease-in-out;
}

.button span {
  background-color: #e8e8e8;

  background-image: 
        /* gloss gradient */ -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(50%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.3)),
      color-stop(100%, rgba(255, 255, 255, 0.2))
    ),
    /* dark outside gradient */ -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(210, 210, 210, 0.3)), color-stop(20%, rgba(210, 210, 210, 0)), color-stop(80%, rgba(210, 210, 210, 0)), color-stop(100%, rgba(210, 210, 210, 0.3))),
    /* light inner gradient */ -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(20%, rgba(255, 255, 255, 0.5)), color-stop(80%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0))),
    /* diagonal line pattern */ -webkit-gradient(linear, 0% 100%, 100% 0%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(40%, rgba(255, 255, 255, 0)), color-stop(40%, #d2d2d1), color-stop(60%, #d2d2d1), color-stop(60%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 0)));

  -webkit-box-shadow: 0px -1px #fff, /* top highlight */ 0px 1px 1px #ffffff; /* bottom edge */

  -webkit-background-size: 100%, 100%, 100%, 4px 4px;

  -webkit-border-radius: 10px;
  -webkit-transition: -webkit-transform 0.1s ease-in-out;

  display: inline-block;
  padding: 5px 20px 5px 20px;

  color: #3a474d;
  text-transform: uppercase;
  font-family: "TradeGothicLTStd-BdCn20", "PT Sans Narrow";
  font-weight: 700;
  font-size: 18px;

  text-shadow: 0px 1px #fff, 0px -1px #262f33;
}

.button span:hover {
  color: #73bfcf;
  text-shadow: 0px -1px #1c6f80;
  cursor: pointer;
}

.button:active {
  -webkit-box-shadow: 0px 3px rgba(128, 128, 128, 1),
    0px 4px rgba(118, 118, 118, 1), 0px 5px rgba(108, 108, 108, 1),
    0px 6px rgba(98, 98, 98, 1), 0px 7px rgba(88, 88, 88, 1),
    0px 8px rgba(78, 78, 78, 1), 0px 10px 2px 0px rgba(128, 128, 128, 0.6); /* shadow */
}

.button:active span {
  -webkit-transform: translate(0, 5px); /* depth of button press */
}
</style>
