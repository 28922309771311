<template>
  <footer class="footer text-center py-3 navbar-fixed-bottom">
    <div class="d-flex justify-content-start mx-4">
      <a href="https://rsb-baden.de/" rel="noreferrer" target="_blank">
        <img
          :src="require('../assets/RobertSchumanSchule.png')"
          width="80"
          height="80"
          alt="Rober Schuman Schule (Logo)"
        />
      </a>
    </div>
    <div class="d-flex justify-content-start mt-4 mx-4 text-left">
      <p>
        <b
          >Dies ist ein Schülerprojekt der Landesfachklasse für
          Veranstaltungskaufleute der Robert-Schuman-Schule in Baden-Baden.</b
        >
      </p>
    </div>
    <div class="d-flex justify-content-start mx-4 text-left">
      <p>
        Robert-Schuman-Schule<br />Rheinstr. 150<br />76532 Baden-Baden <br />
        <a class="ref-link" href="https://rsb-baden.de/" rel="noreferrer" target="_blank"
          >rsb-baden.de</a
        >
      </p>
    </div>
    © 2021 Copyright
    <router-link class="ref-link" to="/impressum">Impressum</router-link>
  </footer>
</template>

<style>
.ref-link {
  color: #004da7;
}

.footer {
  font-size: 0.9em;
  background-color: rgb(194, 194, 194);
  width: 100%;
  border-top: 2px solid black;
}
</style>
