<template>
  <div class="spacer">
    <h1>Sponsoring und Spenden</h1>
  </div>
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-10">
      <p style="font-size: 22px; margin-left: 1em; margin-right: 1em">
        Wir möchten uns im Voraus bei allen Beteiligten herzlich für die
        Unterstützung bedanken, ohne die unser Projekt überhaupt nicht zustande
        gekommen wäre!
      </p>
      <div class="d-flex justify-content-center">
        <div class="content-box text-left" style="margin: 1.5em">
          <h4>Die Haufe Akademie</h4>
          <p>
            Als unser Hauptsponsor erhalten wir Unterstützung von der Haufe
            Akademie GmbH und Co. KG. Die Haufe Akademie wurde 1978 gegründet
            und ist ein Anbieter von Weiterbildungen. Ihr Hauptsitz liegt in
            Freiburg und sie beschäftigt heute rund 400 MitarbeiterInnen. Zudem
            haben sie ca. 1.500 Trainer, Referenten und Coaches unter Vertrag.
            Im Jahr haben sie ungefähr 180.000 Teilnehmer bei ihren Seminaren,
            Tagungen und Kongressen, dort bieten sie 1400 verschiedene Themen in
            23 verschiedenen Bereichen an.
          </p>
          <div class="d-flex justify-content-center">
            <a
              href="https://www.haufe-akademie.de/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                :src="require('../assets/Haufe.jpg')"
                class="img1"
                alt="Haufe"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="content-box text-left" style="margin: 1.5em">
          <h4>Die Agentur mehrpunkt</h4>
          <p>
            Die Agentur mehrpunkt GmbH & Co. KG ist eine Veranstaltungsagentur,
            welche im Oktober 2011 in Freiburg gegründet wurde. Durch sie
            bekommen wir die Location, sowie die technische Ausstattung frei zur
            Verfügung gestellt. In Ihrer Location, die LOKation, wird unser
            Video gefilmt.
          </p>
          <div class="d-flex justify-content-center">
            <a
              href="https://www.mehrpunkt.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                :src="require('../assets/mehrpunkt.jpg')"
                class="img2"
                alt="mehrpunkt"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
</template>

<script>
export default {
  name: "Sponoring",
};
</script>

<style scoped>
.img1 {
  height: auto;
  width: 100%;
  max-width: 280px;
}

.img2 {
  height: auto;
  width: 100%;
  max-width: 120px;
}
</style>