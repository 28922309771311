<template>
  <div id="header" class="row top">
    <div class="col-lg-2"></div>
    <div class="d-flex justify-content-start menu">
      <!--Navbar-->
      <nav class="navbar navbar-fixed-top navbar-light navbar-3 white">
        <!-- Collapse button -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent15"
          aria-controls="navbarSupportedContent15"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Collapsible content -->
        <div
          class="collapse navbar-collapse menu-background text-left"
          id="navbarSupportedContent15"
        >
          <!-- Links -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a
                id="home"
                class="nav-link"
                v-on:click="navigateToHome()"
                href="javascript:void(0);"
                >Home <span class="sr-only">(current)</span></a
              >
            </li>
            <li class="nav-item">
              <a
                id="video"
                class="nav-link"
                v-on:click="navigateToVideo()"
                href="javascript:void(0);"
                >Video</a
              >
            </li>
            <li class="nav-item recipe-menu">
              <a
                id="recipes"
                class="nav-link"
                v-on:click="navigateToRecipes()"
                href="javascript:void(0);"
                >Rezepte</a
              >
            </li>
            <li class="nav-item">
              <a
                id="team"
                class="nav-link"
                v-on:click="navigateToTeam()"
                href="javascript:void(0);"
                >Das Team</a
              >
            </li>
            <li class="nav-item">
              <a
                id="team"
                class="nav-link"
                v-on:click="navigateToRegistration()"
                href="javascript:void(0);"
                >Anmeldung</a
              >
            </li>
            <li class="nav-item">
              <a
                id="cook"
                class="nav-link"
                v-on:click="navigateToSponsoring()"
                href="javascript:void(0);"
                >Sponsoring und Spenden</a
              >
            </li>
          </ul>
          <!-- Links -->
        </div>
        <!-- Collapsible content -->
      </nav>
      <!--/.Navbar-->
    </div>
    <div class="col-lg-8 d-flex justify-content-center">
      <a style="margin: 0px" href="/">
        <img
          id="header-logo"
          style="margin-right: 3px"
          width="210"
          height="210"
          src="~@/assets/logo.png"
          alt="Logo"
        />
      </a>
    </div>
    <div class="col-lg-2"></div>
  </div>
</template>

<script>
export default {
  methods: {
    navigateToHome() {
      this.$router.push("/");
    },
    navigateToTeam() {
      this.$router.push("/team");
    },
    navigateToVideo() {
      this.$router.push("/video");
    },
    navigateToRegistration() {
      this.$router.push("/anmeldung");
    },
    navigateToSponsoring() {
      this.$router.push("/sponsoring-spenden");
    },
    navigateToRecipes() {
      this.$router.push("/rezepte");
    },
  },
};
</script>

<style scoped>
.top {
  background: url("~@/assets/Holz1.jpg");
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  position: fixed;
  width: 100%;
  top: 0;
  margin: 0px auto;
  -webkit-box-shadow: 0px 6px 8px -2px rgba(0, 0, 0, 0.7);
  box-shadow: 0px 6px 8px -2px rgba(0, 0, 0, 0.7);
  z-index: 20;
}

a {
  font-family: "Montserrat", serif;
}

.menu {
  position: absolute;
  z-index: 6;
}

.menu-background {
  background-color: #73bfcf;
  border-radius: 3px;
  padding: 0.5em;
}

.navbar.navbar-3 .navbar-toggler-icon {
  background-image: url("https://mdbootstrap.com/img/svg/hamburger1.svg?color=ededed");
  width: 1.9em;
  height: 1.9em;
}
</style>