<template>
  <div class="spacer">
    <h1>Video</h1>
  </div>
  <div class="row middle">
    <div id="main-content" class="col-lg-8 d-flex justify-content-center">
      <div class="content-box text-left" style="position: relative !important">
        <div class="text-center">
          <video controls preload>
            <source src="Kochvideo.mp4" type="video/mp4" />
          </video>
        </div>
        <p class="video-text">
          Hier werden Sie das Kochvideo finden. Keine Sorge, wenn Sie es jetzt
          noch nicht sehen können. Es wird zu Beginn der Veranstaltung am
          <b>23.04.2021</b> hochgeladen, so dass Sie das Video bequem zum Kochen
          anschauen können. Bis es soweit ist können Sie sich gerne schon mal
          die Rezepte durchlesen. Dazu einfach den Menüpunkt
          <b><a href="/rezepte">Rezepte</a></b> anklicken.
        </p>
      </div>
    </div>
    <div id="second-content" class="col-lg-4 d-flex justify-content-center">
      <chat-component></chat-component>
    </div>
  </div>
</template>

<script>
import ChatComponent from "../components/ChatComponent.vue";
export default {
  name: "Video",
  components: {
    ChatComponent,
  },
};
</script>

<style scoped>
a,
a:hover,
a:visited,
a:active {
  color: inherit;
}

.video-text {
  padding-top: 1.2em;
  margin-top: 1.2em;
  border-top: 2px solid black;
}

video {
  width: 100%;
  max-width: 1024px;
  height: auto;
  max-height: 800px;
}
</style>