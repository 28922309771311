<template>
  <div class="spacer">
    <h1>Impressum</h1>
  </div>
  <div class="row">
    <div class="col-lg-1"></div>
    <div class="col-lg-10 d-flex justify-content-center">
      <div class="content-box text-left" style="margin: 1.5em;">
        <p>
          <b>Kontakt:</b><br />
          Kitchen 101-Team:<br /><br/>
          Teamleitung:<br />Aleyna Kantar<br />
          Pilsenstraße 8 <br />
          79268 Bötzingen <br/><br/>
          Datenschutzbeauftragte: Judith Blum<br />
          E-Mail:
          <a
            href="mailto:kitchen.101@web.de?subject=Kundenfrage&body=Liebes Kitchen 101-Team,"
            >kitchen.101@web.de</a
          ><br /><br />

          <b>Rechtlicher Hinweis:</b><br />
          Für den Inhalt der Seiten, die von dieser Seite aus per Link
          erreichbar sind, sind die jeweiligen Betreiber / Verfasser selbst
          verantwortlich und haftbar.<br /><br />

          <b>Konzeption und Gestaltung:</b><br />
          Selina Kuolt<br /><br />

          <b>Technische Umsetzung:</b><br />
          Roland Burke<br />
        </p>
      </div>
    </div>
    <div class="col-lg-1"></div>
  </div>
</template>

<script>
export default {
  name: "Impressum",
};
</script>
