<template>
  <div class="row middle">
    <div class="col-lg-2"></div>
    <div id="main-content" class="col-lg-8 d-flex justify-content-center">
      <div class="content-box text-left">
        <h2>Kitchen 101 -<br />Making the basics awesome!</h2>
        <div></div>
        <p>
          Haben Sie Lust auf ein wenig Abwechslung in diesen Zeiten? Und ein
          wenig Spaß am Kochen? Dann sind Sie hier genau richtig! Mit unserem
          Projekt „Kitchen 101“ wollen wir beweisen, dass es möglich ist, sich
          auf Distanz zu „treffen“, sich auszutauschen und gemeinsam Spaß zu
          haben. Deshalb wollen wir für alle Kochbegeisterten oder die, die es
          werden wollen, ein virtuelles Koch-Event auf die Beine stellen, das
          bestimmt nicht so schnell in Vergessenheit geraten wird.<br /><br />Gemeinsam
          erleben Sie einen Abend an dem Sie ein tolles Zwei-Gänge-Menü mit
          einfachen Zutaten zubereiten. Vor der Veranstaltung erhalten Sie eine
          Kochbox mit allen nötigen Zutaten für die leckeren Rezepte. Mit einem
          von uns zur Verfügung gestellten Video bekommen Sie eine
          Schritt-für-Schritt-Anleitung, mit der Ihnen die Gerichte ganz bestimmt gelingen.
          <br /><br />
          Alle wichtigen Informationen finden Sie hier auf der Webseite,
          und was noch besser ist, Sie können sich auch gleich mit unserem Anmeldeformular anmelden!
          Klicken Sie sich doch einfach mal durch. Wir wünschen auf jeden Fall
          schon mal viel Spaß.<br /><br />Ihr Kitchen 101-Team
        </p>
      </div>
    </div>
    <div class="col-lg-2"></div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
