<template>
  <div class="col content-box chat-frame">
    <h1>Chat</h1>
    <div id="chatbox" class="chat">
      <div v-for="m in $store.getters.messages" :key="m">
        <div class="text-left" style="margin: 0 0 0 0.5em">
          <label style="font-size: 0.8em; margin-bottom: 0px"
            >{{ m.name }}:</label
          >
        </div>
        <div class="chat-line d-flex justify-content-start text-left">
          <label style="margin: 0px; padding-right: 1em">{{
            m.timestamp
          }}</label>
          <label style="margin: 0px">{{ m.message }}</label>
        </div>
      </div>
    </div>
    <div
      class="chat-elements d-flex flex-row flex-column justify-content-around align-items-center"
    >
      <div v-if="$store.getters.isAuthenticated" class="chat-input">
        <label style="margin: 0px; font-size: 0.8em">Maximal {{MAX_MESSAGE_LENGTH}} Zeichen</label>
        <input
          type="text"
          class="chat-input-inner"
          v-model="message"
          @keyup.enter="sendMessage()"
          placeholder="Nachricht schreiben..."
        />
      </div>
      <div v-else class="chat-input">
        <label>Passwort eingeben um chatten zu können:</label>
        <input
          id="chat-input-field"
          type="password"
          v-model="message"
          @keyup.enter="sendMessage()"
          placeholder="Passwort"
        />
      </div>
      <div v-if="!websocketConnected">
        <label
          ><b
            >Die Chat Sitzung ist abgelaufen, bitte die Seite aktualisieren!</b
          ></label
        >
      </div>
      <div>
        <a @click="sendMessage()" class="button">
          <span>Senden</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "ChatComponent",
  data: function () {
    return {
      websocket: null,
      message: "",
      MAX_MESSAGE_LENGTH: 250,
      websocketConnected: false,
      websocketRetryCounter: 5,
    };
  },
  mounted: function () {
    this.setupWebsocket();
  },
  methods: {
    setupWebsocket: function () {
      let self = this;
      this.websocket = new WebSocket("wss://api.kitchen101.de");
      this.websocket.onopen = function (event) {
        console.log("Successfully Connected: " + event.type);
        self.websocketConnected = true;
        self.websocket.send(self.buildAuthMessage());
      };
      this.websocket.onmessage = function (rawMessage) {
        const data = $.parseJSON(rawMessage.data);
        if (data.type == 0) {
          self.$store.dispatch("updateMessages", data.messageList);
        } else if (data.type == 1) {
          self.$store.dispatch("addMessage", {
            message: data.newMessage,
            timestamp: data.timestamp,
            name: data.name,
          });
        } else if (data.type == 2) {
          self.$store.dispatch("updateAuthentification", true);
          window.$cookies.set("id", data.id, "2d");
        } else if (data.type == 3) {
          if (self.$store.getters.isAuthenticated) {
            alert(
              "Ihre Sitzung ist abgelaufen! Bitte das Passwort nochmal eingeben."
            );
          } else {
            alert("Falsches Passwort!");
          }
          self.$store.dispatch("updateAuthentification", false);
        } else {
          console.log("Invalid Data: " + JSON.stringify(data));
        }
      };
      this.websocket.onclose = function () {
        if (this.websocketRetryCounter > 0) {
          this.websocketRetryCounter--;
          setTimeout(this.setupWebsocket, 500);
        } else {
          self.websocketConnected = false;
        }
      };
    },
    sendMessage: function () {
      if (this.message.length > 0 && this.message.indexOf(" ") !== 0) {
        if (this.websocket.readyState === WebSocket.OPEN) {
          this.websocket.send(this.buildMessage());
          setTimeout(function () {
            var objDiv = document.getElementById("chatbox");
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 50);
        } else {
          this.websocketConnected = false;
        }
      }
      this.message = "";
    },
    checkIdCookie: function () {
      if (window.$cookies.isKey("id")) {
        return window.$cookies.get("id");
      }
      return -1;
    },
    buildMessage: function () {
      let msg;
      if (this.message.length < this.MAX_MESSAGE_LENGTH) {
        msg = this.message;
      } else {
        msg = this.message.slice(0, this.MAX_MESSAGE_LENGTH);
      }

      return JSON.stringify({
        type: 1,
        message: msg,
        id: this.checkIdCookie(),
      });
    },
    buildAuthMessage: function () {
      return JSON.stringify({
        type: 2,
        message: "AUTH",
        id: this.checkIdCookie(),
      });
    },
  },
};
</script>

<style scoped>
.chat-frame {
  max-width: 36em;
  font-family: "Montserrat", serif;
}
.chat-input {
  width: 100%;
  max-width: 25em;
  margin: 1em;
}
.chat-input-inner {
  width: 100%;
}
.chat {
  border-radius: 6px;
  background-color: white;
  max-height: 30em;
  overflow-y: auto;
  border: 2px solid black;
}
.chat-line {
  border-radius: 6px;
  border: 1px solid black;
  margin: 0 0.5em 0.2em 0.5em;
  padding: 0.2em;
  overflow-wrap: break-word;
  overflow: hidden;
}
</style>