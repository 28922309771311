<template>
  <div class="spacer">
    <h1>Rezepte</h1>
  </div>
  <div class="row middle">
    <div class="col-lg-2"></div>
    <div id="main-content" class="col-lg-8 d-flex justify-content-center">
      <div class="content-box text-left">
        <p>
          Unser Menü setzt sich aus einem Hauptgang und einem Dessert zusammen.
          Alle Komponenten haben wir so zusammengesetzt, dass sie möglichste
          Allergien frei sind.
        </p>
        <h5>Als Hauptgang kochen wir zusammen einen Asiatischen Wok mit folgenden
          Zutaten:</h5>
        <ul>
          <li>Reis</li>
          <li>Kartoffeln</li>
          <li>Süßkartoffeln</li>
          <li>Karotten</li>
          <li>Bohnen</li>
          <li>Zwiebeln</li>
          <li>Currymischung</li>
          <li>Kokosmilch</li>
          <li>Salz</li>
          <li>Pfeffer</li>
          <li>Öl</li>
        </ul>
        <h5>Rezept für den Asia Wok:</h5>
        <ol>
          <li>
            Den Reis in einen Kochtopf geben. Wasser hinzufügen, so dass der
            Reis bedeckt ist und das Salz nicht vergessen. Anschließend den Reis
            kochen.
          </li>
          <li>Währenddessen das Gemüse in mundgerechte Stücke schnippeln.</li>
          <li>
            Anschließend das Gemüse in einem Wok (alternativ einfach eine große
            Pfanne) anbraten. Hierzu Öl in den Wok geben und das Gemüse in
            folgender Reihenfolge dazu geben:
            <br />- Kartoffeln und Süßkartoffeln, da diese am längsten brauchen
            <br />- Karotten<br />- Zuckererbsen<br />- Zwiebeln, als letztes
          </li>
          <li>
            Während das Gemüse vor sich hin kocht, immer mal wieder nach dem
            Reis schauen.
          </li>
          <li>
            Wenn das Gemüse etwas gekocht hat, ist die Soße an der Reihe. Dazu
            geben wir die Kokosmilch in den Wok und lassen sie etwas aufkochen.
            Zuletzt das Currypulver hinzugeben.
          </li>
          <li>
            Wir lassen alles etwas aufkochen und richten alles zusammen an, wenn
            das Gemüse und der Reis fertiggekocht sind.
          </li>
          <li>
            Wir wünschen einen guten Appetit! Lassen Sie es sich schmecken 😊.
          </li>
        </ol>
        <br>
        <br>
        <h5>
          Als Dessert backen wir zusammen leckere Brownies. Sie sind vegan und
          beinhalten:
        </h5>
        <ul>
          <li>Mehl</li>
          <li>Zucker</li>
          <li>Kakaopulver</li>
          <li>dunkle Schokolade</li>
          <li>Apfelmus</li>
          <li>pflanzliches Öl</li>
          <li>pflanzliche Milch</li>
        </ul>
        <h5>Rezept für die Brownies:</h5>
        <ol>
          <li>Den Ofen auf 180° C Umluft vorheizen</li>
          <li>Die dunkle Schokolade im Wasserbad schmelzen.</li>
          <li>Für die Brownies zuerst alle trockenen Zutaten in einer großen Schüssel verrühren: Mehl, Zucker und Kakaopulver.</li>
          <li>Danach die geschmolzene Schokolade, das Apfelmus, Öl und pflanzliche Milch zu den trockenen Zutaten geben, gut unterrühren und in eine eingefettete Form (20x25cm) geben.</li>
          <li>Den Teig für 25-30 Minuten im Ofen backen. Am besten schmecken die Brownies noch lauwarm mit ein paar frischen Beeren.</li>
          <li>Guten Appetit!</li>
        </ol>
        <p>
          Wie die tollen Speisen zubereitet werden erfahren Sie in unserem
          Kochvideo am 23.04.21.<br /><br />
          Bis dahin wünschen wir Ihnen alles Gute!<br />
          Ihr Kitchen 101-Team
        </p>
      </div>
    </div>
    <div class="col-lg-2"></div>
  </div>
</template>

<script>
export default {
  name: "Recipes",
};
</script>

<style scoped>
h4, h5, h6, ul, ol {
  font-family: "Montserrat", serif;
}
</style>
