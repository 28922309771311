<template>
  <div class="row d-flex justify-content-center">
    <div v-for="index in number" :key="index" class="cooking-sketch">
      <img width="80" height="56.25" src="~@/assets/cooking_sketch.png" alt="cooking_sketch"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoeffelComponent",
  data: function () {
    return {
      number: 5,
    };
  },
  created() {
    window.addEventListener("resize", this.setImages);
    this.setImages();
  },
  unmounted() {
    window.removeEventListener("resize", this.setImages);
  },
  methods: {
    setImages() {
      const img_width = 80;

      const offset = 1;

      let style_bar_x_margin = offset * img_width;
      this.number = Math.round(
        (window.innerWidth - style_bar_x_margin) / img_width
      );
    },
  },
};
</script>

<style scoped>
.cooking-sketch {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
</style>