<template>
  <div class="row content-box d-flex justify-content-center">
    <div class="col-lg-4 row d-flex align-items-center justify-content-center">
      <img
        :src="require(`../assets/team/${imgUrl}`)"
        class="image"
        :alt="name"
      />
    </div>
    <div class="col-lg-8 team-text d-flex align-items-center text-left">
      <p style="margin: 0px;">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonComponent",
  props: {
    width: Number,
    height: Number,
    imgUrl: String,
    name: String,
    description: String,
  },
  data: function () {
    return {};
  },
};
</script>

<style scoped>
.image {
  border: 2px solid rgb(255, 255, 255);
}

img {
  height: auto;
  width: 100%;
  max-width: 240px;
}

@media (min-width: 992px) {
  .team-text {
    padding-left: 1.2em;
    margin-left: 1.2em;
    border-left: 2px solid black;
  }
}

@media (max-width: 991px) {
  .team-text {
    padding-top: 1.2em;
    margin-top: 1.2em;
    border-top: 2px solid black;
  }
}
</style>