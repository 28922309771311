import { createStore } from 'vuex'

export default createStore({
  state: {
    images: [
      {
        name: "Aleyna",
        imgUrl: "Aleyna.jpg",
        width: 240,
        height: 320,
      },
      {
        name: "Anna-Lena",
        imgUrl: "Anna-Lena.jpg",
        width: 240,
        height: 360,
      },
      {
        name: "Judith",
        imgUrl: "Judith.jpg",
        width: 240,
        height: 441,
      },
      {
        name: "Kira",
        imgUrl: "Kira.jpg",
        width: 240,
        height: 293,
      },
      {
        name: "Selina",
        imgUrl: "Selina.jpg",
        width: 240,
        height: 368,
      },
    ],
    messageList: [],
    authenticated: false
  },
  mutations: {
    ADD_MESSAGE(state, msgObject) {
      state.messageList.push(msgObject);
    },
    UPDATE_MESSAGES(state, messageList) {
      state.messageList = messageList;
    },
    UPDATE_AUTHENTICATION(state, newValue) {
      state.authenticated = newValue;
    }
  },
  actions: {
    addMessage(context, msgObject) {
      context.commit("ADD_MESSAGE", msgObject);
    },
    updateMessages(context, messageList) {
      context.commit("UPDATE_MESSAGES", messageList);
    },
    updateAuthentification(context, newValue) {
      context.commit("UPDATE_AUTHENTICATION", newValue);
    }
  },
  getters: {
    images(state) {
      return state.images;
    },
    messages(state) {
      return state.messageList;
    },
    isAuthenticated(state) {
      return state.authenticated;
    }
  }
})
