<template>
  <div class="spacer">
    <h1>Anmeldung</h1>
  </div>
  <div class="row middle">
    <div class="col-lg-2"></div>
    <div id="main-content" class="col-lg-8 d-flex justify-content-center">
      <div class="content-box text-left">
        <p>
          Hier finden Sie das Anmeldeformular sowie die Datenschutzerklärung.
          Wenn Sie sich Anmelden möchten, beachten Sie bitte, dass unser Projekt
          der Corona-Verordnung vom 17.03.20 unterliegt. Daher dürfen sich nur
          Personen aus einem Haushalt für die Kochbox für zwei Personen
          anmelden. Wir danken für Ihr Verständnis und freuen uns schon auf Ihre
          Anmeldung.<br /><br />
          Ihr Kitchen 101-Team<br /><br />
          <b>Anmeldefrist: 10.04.2021</b><br><br>
          Zum anmelden einfach das Formular herunterladen, ausfüllen und an
          diese E-Mail:
          <a
            href="mailto:kitchen.101@web.de?subject=Anmeldung&body=Liebes Kitchen 101-Team,"
            >kitchen.101@web.de</a
          >
          zurücksenden.
        </p>
        <a
          :href="require('@/assets/anmeldung.pdf')"
          class="button"
          download="Anmeldeformular_und_Datenschutz"
        >
          <span style="font-size: 0.8em">Formular herunterladen (.pdf)</span>
        </a>
        <a
          :href="require('@/assets/anmeldung.docx')"
          class="button"
          download="Anmeldeformular_und_Datenschutz"
        >
          <span style="font-size: 0.8em">Formular herunterladen (.docx)</span>
        </a>
      </div>
    </div>
    <div class="col-lg-2"></div>
  </div>
</template>

<script>
export default {
  name: "Registration",
};
</script>
